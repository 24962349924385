/*
 * @Author: hwu
 * @Date: 2020-12-11 11:56:12
 * @Description: 支付类接口
 * @LastEditTime: 2021-12-08 18:58:23
 */

import { posHttp } from '@/utils/axios'

/**
 * 0元支付
 * @param {Object} paramVo 支付参数
 */
export const payByZeroApi = (paramVo) => posHttp.post(`/pay/zero`, paramVo)

/**
 * 线下支付（刷卡/现金)
 * @param {Object} paramVo 支付参数
 */
export const payByOfflineApi = (paramVo) => posHttp.post(`/pay/offline`, paramVo)

/**
 * 微信扫付款码支付
 * @param {Object} paramVo 支付参数
 */
export const payByWxBarCodeApi = (paramVo) => posHttp.post(`/pay/wechat/barCodePay`, paramVo)

/**
 * 支付宝扫付款码支付
 * @param {Object} paramVo 支付参数
 */
export const payByAlipayBarCodeApi = (paramVo) => posHttp.post(`/pay/alipay/barCodePay`, paramVo)

/**
 * 会员卡支付参数缓存（员工推荐购卡，顾客扫码支付，临时版本，后面功能迭代，可能就不这么做了）
 * @param {*} paramVo
 * @returns
 */
export const saveCardPaymentParamApi = (paramVo) => posHttp.post(`/pay/card/param/save`, paramVo)
