<!--
 * @Author: hwu
 * @Date: 2021-12-09 18:12:19
 * @Description: 树状选择弹窗
 * @LastEditTime: 2024-05-27 11:52:27
-->
<template>
  <popup-box :show="show" position="bottom" @onClose="onOverlayClick" :popupStyle="'height:' + height">
    <div class="w-popup-container">
      <div class="w-popup-header">
        <!-- 顶部标题栏 -->
        <div class="w-popup-header_title" v-if="title">{{ title }}</div>
      </div>
      <div class="w-popup-body with-footer" :class="{ 'with-header': title }">
        <div class="w-tree-wrapper">
          <div class="w-tree-total" v-if="editable && multi && showAllSelect && allOptionsNum > 0">
            <!-- 因为暂时c端的目前没有选择的需求,所以暂时没换,后面如果需要,这里的checkbox要替换掉,参照PaymentCommentPopup里面的选择商品 -->
            <van-checkbox v-model="allSelected" @change="checkAllOptions()" />
            <span class="w-tree-total_text">{{ allSelectTitle }} ({{ allSelectedNum }}/{{ allOptionsNum }})</span>
          </div>
          <div class="w-tree-branch">
            <van-collapse ref="collapse" v-model="activeCollapseNames" :border="false">
              <van-collapse-item v-for="(group, index) in treeData" :key="index" v-show="!group.hide && !group.awalysHide" :name="group.id" :clickable="false" :border="false" :show-animation="false">
                <template v-slot:title>
                  <div class="w-tree-branch_title" :class="{ disabled: group.disabled }">
                    <w-checkbox v-model="group.selected" @change="checkGroupOptions(group)" :disabled="group.disabled" v-if="editable && multi" />
                    <span>{{ group.name }}</span>
                    <span v-if="editable">{{ group.children | groupSelectedResultFilter }}</span>
                    <span v-else>{{ group.children | groupSelectedResultForReadFilter }}</span>
                  </div>
                </template>
                <div class="w-tree-branch_content">
                  <div class="w-tree-node" v-for="(item, itemIndex) in group.children" :key="itemIndex" v-show="!item.hide && !item.awalysHide" :style="{ 'flex-basis': btnWidth }">
                    <div class="w-tree-node_btn" :class="{ active: item.selected, 'other-active': item.otherSelected, disabled: item.disabled }" @click.stop.prevent="checkOption(item, group)">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </van-collapse-item>
            </van-collapse>
            <div class="w-tree-empty" v-if="!allOptionsNum">
              <div class="wh-tree-empty_row">{{ emptyText }}</div>
            </div>
          </div>
        </div>
        <btn-footer :show-left-btn="showLeftBtn" :show-right-btn="showRightBtn" :left-btn-text="leftBtnText" :right-btn-text="rightBtnText" @onLeftClick="onLeftClick" @onRightClick="onRightClick" />
      </div>
    </div>
  </popup-box>
</template>
<script>
import PopupBox from '@/components/common/PopupBox'
import BtnFooter from '@/components/common/BtnFooter'
import { Checkbox, Collapse, CollapseItem } from 'vant'
export default {
  name: 'tree-select-popup',
  components: {
    PopupBox,
    BtnFooter,
    VanCollapse: Collapse,
    VanCollapseItem: CollapseItem,
    VanCheckbox: Checkbox
  },
  props: {
    show: { type: Boolean, required: true, desc: '是否显示弹窗', default: false },
    data: { type: Array, required: true, desc: '弹窗数据', default: () => [] },
    title: { type: String, required: false, desc: '弹窗标题', default: '' },
    showAllSelect: { type: Boolean, required: false, desc: '是否展示全选框（单选和只读的情况下默认不展示）', default: true },
    allSelectTitle: { type: String, required: false, desc: '全选的标题', default: '所有' },
    height: { type: String, required: false, desc: '弹窗高度', default: '80%' },
    rowNum: { type: Number, required: false, desc: '每行显示按钮数', default: 3 },
    editable: { type: Boolean, required: false, desc: '是否可以编辑', default: true },
    multi: { type: Boolean, required: false, desc: '是否支持多选', default: true },
    emptyText: { type: String, required: false, desc: '当搜索无数据时侯的提示文本', default: '没有符合条件的数据' },
    showLeftBtn: { type: Boolean, required: false, desc: '是否显示底部左侧按钮', default: true },
    showRightBtn: { type: Boolean, required: false, desc: '是否显示底部右侧按钮', default: true },
    leftBtnText: { type: String, required: false, desc: '底部左侧按钮文本', default: '返回' },
    rightBtnText: { type: String, required: false, desc: '底部右侧按钮文本', default: '选好了' },
    closeOnClickOverlay: { type: Boolean, required: false, desc: '点击遮罩是否关闭弹窗', default: true },
    closeOnClickLeft: { type: Boolean, required: false, desc: '点击左侧按钮是否关闭弹窗', default: true },
    closeOnClickRight: { type: Boolean, required: false, desc: '点击右侧侧按钮是否关闭弹窗', default: true },
    selectedIds: { type: Array, required: false, desc: '已选中的Id列表', default: () => [] },
    otherSelectedIds: { type: Array, required: false, desc: '已被其他项目绑定的Id列表', default: () => [] },
    disableOtherSelected: { type: Boolean, required: false, desc: '是否禁用已被其他对象绑定的选项', default: true },
    disabledIds: { type: Array, required: false, desc: '被禁用的Id列表', default: () => [] }
  },
  data() {
    return {
      popupShow: false,
      allSelected: false,
      activeCollapseNames: [],
      treeData: []
    }
  },
  filters: {
    groupSelectedResultFilter(val) {
      if (!val || val.length === 0) {
        return '（0/0）'
      }
      // 计数的时候，把隐藏的过滤掉
      val = val.filter((i) => {
        return !i.hide && !i.alwaysHide
      })
      const selectedChirdren = val.filter((i) => {
        return i.selected
      })
      const selectedChirdrenNum = selectedChirdren.length
      const allChildrenNum = val.length
      return `（${selectedChirdrenNum}/${allChildrenNum}）`
    },
    groupSelectedResultForReadFilter(val) {
      if (!val || val.length === 0) {
        return '（0）'
      }
      const selectedChirdren = val.filter((i) => {
        return i.selected
      })
      const selectedChirdrenNum = selectedChirdren.length
      return `（${selectedChirdrenNum}）`
    }
  },
  computed: {
    btnWidth() {
      return (100 / this.rowNum).toFixed(2) + '%'
    },
    allOptionsNum() {
      return this.treeData
        .reduce((result, item) => {
          return result.concat(item.children)
        }, [])
        .filter((i) => !i.hide && !i.alwaysHide).length
    },
    allSelectedNum() {
      const selectedChildren = this.treeData
        .reduce((result, item) => {
          return result.concat(item.children)
        }, [])
        .filter((x) => x.selected && !x.hide && !x.alwaysHide)

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.allSelected = selectedChildren.length >= this.allOptionsNum

      return selectedChildren.length || 0
    }
  },
  watch: {
    show(val) {
      this.popupShow = val
      if (val) {
        this.initTreeData()
      }
    },
    data() {
      this.initTreeData()
    },
    selectedIds() {
      this.initTreeData()
    },
    disabledIds() {
      this.initTreeData()
    },
    otherSelectedIds() {
      this.initTreeData()
    }
  },
  created() {
    this.initTreeData()
  },
  methods: {
    initTreeData() {
      this.treeData = JSON.parse(JSON.stringify(this.data))
      this.allSelected = false
      this.treeData.forEach((group) => {
        let groupSelected = true
        let groupDisabled = true
        let groupAwalysHide = true
        group.children.forEach((item) => {
          if (this.selectedIds.indexOf(item.id) > -1) {
            item.selected = true
          }
          if (this.otherSelectedIds.indexOf(item.id) > -1) {
            item.otherSelected = true
            // 如果被其他对象选中并且不可选，则认为商品是禁用的
            if (this.disableOtherSelected) {
              item.disabled = true
            }
          }
          if (this.disabledIds.indexOf(item.id) > -1) {
            item.disabled = true
          }
          // 如果是只读的，并且没有被选中，则隐藏
          if (!this.editable && !item.selected) {
            item.awalysHide = true
          }

          if (!item.selected) {
            groupSelected = false
          }
          if (!item.disabled) {
            groupDisabled = false
          }
          if (!item.awalysHide) {
            groupAwalysHide = false
          }
        })
        group.selected = groupSelected
        group.disabled = groupDisabled
        group.awalysHide = groupAwalysHide
      })

      // resize 方法解决动态添加数据，带动画的折叠面板高度不更新的问题
      this.$nextTick(() => {
        this.activeCollapseNames = this.treeData.filter((x) => x.children.some((y) => y.selected)).map((x) => x.id)
        // this.$refs.collapse.resize()
      })
    },
    checkAllOptions() {
      this.treeData.forEach((group) => {
        group.selected = this.allSelected
        group.children.forEach((item) => {
          if (item.disabled) {
            return true
          }
          if (item.hide || item.alwaysHide) {
            return true
          }
          item.selected = this.allSelected
        })
      })
      this.treeData = JSON.parse(JSON.stringify(this.treeData))
    },
    checkGroupOptions(group) {
      if (group.disabled || !this.editable) {
        return false
      }
      group.children.forEach((item) => {
        if (item.disabled) {
          return true
        }
        if (item.hide || item.alwaysHide) {
          return true
        }
        if (item.otherSelected && this.disableOtherSelected) {
          return true
        }
        item.selected = group.selected
      })
      // 上面数据更新了，但是dom没更新，所以用深度拷贝来处理一下
      this.treeData = JSON.parse(JSON.stringify(this.treeData))
    },
    checkOption(item, group) {
      if (item.disabled || !this.editable) {
        return
      }
      if (item.otherSelected && this.disableOtherSelected) {
        return
      }
      // 如果是单选，并且当前操作是要选中，则需要把其他选中的全部取消选中
      if (!this.multi && !item.selected) {
        this.treeData.forEach((val) => {
          val.selected = false
          val.children.forEach((i) => {
            i.selected = false
          })
        })
      }
      item.selected = !item.selected
      let groupSelected = true
      group.children.forEach((item) => {
        if (item.selected) {
          return true
        }
        groupSelected = false
      })
      group.selected = groupSelected
      this.treeData = JSON.parse(JSON.stringify(this.treeData))
    },
    onOverlayClick(e) {
      if (this.closeOnClickOverlay) {
        this.$emit('update:show', false)
        this.$emit('onClose')
      }
      this.$emit('onOverlayClick', e)
    },
    onLeftClick(e) {
      if (this.closeOnClickLeft) {
        this.$emit('update:show', false)
        this.$emit('onClose')
      }
      this.$emit('onLeftClick', e)
    },
    onRightClick(e) {
      if (this.closeOnClickRight) {
        this.$emit('update:show', false)
        this.$emit('onClose')
      }
      const selectedList = this.treeData
        .reduce((result, item) => {
          return result.concat(item.children)
        }, [])
        .filter((x) => x.selected)
      if (this.multi) {
        this.$emit('onRightClick', selectedList)
      } else {
        let result
        if (selectedList && selectedList.length > 0) {
          result = selectedList[0]
        } else {
          result = {}
        }
        this.$emit('onRightClick', result, e)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.w-popup-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $color-background;
  overflow: hidden;
  box-sizing: border-box;
  .w-popup-header {
    position: absolute;
    // display: flex;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 24px;
    align-items: center;
    background-color: $color-white;
    z-index: 1000;
    box-sizing: border-box;

    .w-popup-header_title {
      position: relative;
      width: 100%;
      padding: 22px 0;
      line-height: 44px;
      font-size: 30px;
      font-weight: bold;
      color: $color-text-main;
      box-sizing: border-box;
      &:after {
        content: '';
        position: absolute;
        pointer-events: none;
        box-sizing: border-box;
        top: -50%;
        right: -50%;
        bottom: -50%;
        left: -50%;
        border-bottom: 1px solid $color-border;
        transform: scale(0.5);
      }
    }
  }

  .w-popup-body {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    &.with-header {
      padding-top: 88px;
      &.with-filter {
        padding-top: 176px;
      }
    }
    &.with-footer {
      padding-bottom: 100px;
    }

    &.with-filter {
      padding-top: 88px;
    }

    &.with-footer {
      padding-bottom: 100px;
    }
  }

  .w-popup-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 750px;
    background: $color-white;
  }
}
.w-tree-wrapper {
  width: 100%;
  // height: 100%;
  .w-tree-total {
    width: 100%;
    padding: 24px;
    background-color: $color-white;
    .w-tree-total_text {
      font-size: 30px;
      color: $color-text-main;
    }
  }
  .w-tree-branch {
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    background: $color-white;

    /deep/ .van-collapse-item__title {
      padding: 0 !important;
      align-items: center !important;
    }
    /deep/ .van-collapse-item__content {
      padding: 0 !important;
    }
    .w-tree-branch_title {
      width: 100%;
      padding: 20px 0;
      box-sizing: border-box;
      &.disabled {
        opacity: 0.59;
      }
    }
    .w-tree-branch_content {
      display: flex;
      width: 100%;
      padding: 12px 0;
      margin: 0 -12px;
      flex-wrap: wrap;
      align-content: center;
      box-sizing: border-box;

      .w-tree-node {
        flex: 0 0 33.33%;
        padding: 6px 12px;
        overflow: hidden;
        box-sizing: border-box;
        .w-tree-node_btn {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          height: 72px;
          padding: 6px 12px;
          line-height: 30px;
          justify-content: center;
          font-size: 24px;
          text-align: center;
          color: $color-text-normal;
          background-color: $color-background;
          border: 2px solid $color-background;
          border-radius: 8px;
          overflow: hidden;
          box-sizing: border-box;
          &.active {
            color: $color-primary;
            background: #f7faff;
            font-weight: bold;
            border: 2px solid $color-primary;
            &.disabled {
              color: $color-primary;
              background: #f7faff;
              font-weight: bold;
              border: 2px solid $color-primary;
              opacity: 0.59;
            }
          }
          &.disabled {
            color: $color-text-normal;
            border: 2px solid $color-background;
            background: $color-background;
            opacity: 0.59;
          }
          &.other-active {
            &:not(.active):before {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              border: 20px solid $color-primary-sub;
              border-left-color: transparent;
              border-bottom-color: transparent;
            }
            &:not(.active):after {
              content: '';
              width: 4px;
              height: 10px;
              position: absolute;
              top: 3px;
              right: 8px;
              border: 2px solid #fff;
              border-top-color: transparent;
              border-left-color: transparent;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }
}
.w-tree-empty {
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  padding: 40px 117px;
  text-align: center;
  box-sizing: border-box;
  .wh-tree-empty_icon {
    display: inline-block;
    margin-bottom: 8px;
    font-size: 80px;
  }

  .wh-tree-empty_row {
    line-height: 45px;
    font-size: 28px;
    color: $color-text-normal;
  }
}
</style>
