<!--
 * @Author: hwu
 * @Date: 2021-11-10 13:36:21
 * @Description: 商品选择弹窗
 * @LastEditTime: 2022-05-18 17:58:02
-->
<template>
  <tree-select-popup
    :show="show"
    :data="itemOptions"
    :title="popupTitle"
    :allSelectTitle="allSelectTitle"
    :height="height"
    :rowNum="rowNum"
    :editable="editable"
    :multi="multi"
    :emptyText="emptyText"
    :showLeftBtn="customShowLeftBtn"
    :showRightBtn="showRightBtn"
    :leftBtnText="leftBtnText"
    :rightBtnText="customRightBtnText"
    :selectedIds="customSelectedIds"
    :otherSelectedIds="otherSelectedIds"
    :disableOtherSelected="disableOtherSelected"
    :disabledIds="disabledIds"
    :closeOnClickOverlay="closeOnClickOverlay"
    :closeOnClickLeft="closeOnClickLeft"
    :closeOnClickRight="closeOnClickRight"
    @onLeftClick="onLeftClick"
    @onRightClick="onRightClick"
    @onOverlayClick="onOverlayClick"
  />
</template>
<script>
import TreeSelectPopup from '@/components/common/TreeSelectPopup'
import { listItemsForSelectPopupApi } from '@/api/item'
export default {
  name: 'item-select-popup',
  components: { TreeSelectPopup },
  props: {
    show: { type: Boolean, required: true, desc: '是否显示弹窗', default: false },
    title: { type: String, required: false, desc: '弹窗标题', default: '' },
    allSelectTitle: { type: String, required: false, desc: '全选的标题', default: '所有商品' },
    height: { type: String, required: false, desc: '弹窗高度', default: '90%' },
    rowNum: { type: Number, required: false, desc: '每行显示按钮数', default: 3 },
    editable: { type: Boolean, required: false, desc: '是否可以编辑', default: true },
    multi: { type: Boolean, required: false, desc: '是否支持多选', default: true },
    showLeftBtn: { type: Boolean, required: false, desc: '是否显示底部左侧按钮', default: true },
    showRightBtn: { type: Boolean, required: false, desc: '是否显示底部右侧按钮', default: true },
    leftBtnText: { type: String, required: false, desc: '底部左侧按钮文本', default: '返回' },
    rightBtnText: { type: String, required: false, desc: '底部右侧按钮文本', default: '' },
    disableOtherSelected: { type: Boolean, required: false, desc: '是否禁用已被其他对象绑定的选项', default: true },
    selectedIds: { type: Array, required: false, desc: '已选中的Id列表', default: () => [] },
    otherSelectedIds: { type: Array, required: false, desc: '已被其他对象绑定的Id列表', default: () => [] },
    closeOnClickOverlay: { type: Boolean, required: false, desc: '点击遮罩是否关闭弹窗', default: true },
    closeOnClickLeft: { type: Boolean, required: false, desc: '点击左侧按钮是否关闭弹窗', default: true },
    closeOnClickRight: { type: Boolean, required: false, desc: '点击右侧侧按钮是否关闭弹窗', default: true },
    disabledIds: { type: Array, required: false, desc: '被禁用的Id列表', default: () => [] },
    brandCode: { type: String, required: true, desc: '品牌编号', default: '' },
    itemLevel: { type: Number, required: false, desc: '商品层级, 0-商品层，1-规格层，2-口味层', default: 1 },
    excludeIds: { type: Array, required: false, desc: '不参与的商品(用于与商品列表反向过滤出参与的商品)', default: () => [] }
  },
  data () {
    return {
      popupTitle: '',
      itemOptions: [],
      totalItemNum: 0,
      emptyText: '没有符合条件的商品',
      customRightBtnText: '选好了',
      customShowLeftBtn: true,
      customSelectedIds: []
    }
  },
  watch: {
    brandCode: {
      handler (val) {
        if (val) {
          this.listItemsOptions()
        }
      },
      immediate: true
    },
    itemLevel () {
      this.listItemsOptions()
    },
    selectedIds () {
      this.generateCustomSelectedIds()
    },
    excludeIds () {
      this.generateCustomSelectedIds()
    }
  },
  created () {
    // 标题
    if (!this.title) {
      if (this.editable) {
        this.popupTitle = '选择商品'
      } else {
        this.popupTitle = `指定商品`
      }
    }
    // 底部栏
    if (this.rightBtnText) {
      // 如果自定义了底部栏信息，则按照定义的来
      this.customRightBtnText = this.rightBtnText
      this.customShowLeftBtn = this.showLeftBtn
    } else {
      // 如果没有自定义，则按照默认的来区分编辑和查看
      if (this.editable) {
        this.customRightBtnText = '选好了'
        this.customShowLeftBtn = true
      } else {
        this.customRightBtnText = '返回'
        this.customShowLeftBtn = false
      }
    }
  },
  methods: {
    listItemsOptions () {
      this.emptyText = '商品加载中'
      listItemsForSelectPopupApi(this.brandCode, this.itemLevel).then((res) => {
        this.emptyText = '没有符合条件的数据'
        if (res.status !== '1000' || !res.data || res.data.length === 0) {
          this.itemOptions = []
          return false
        }
        this.itemOptions = res.data
        this.totalItemNum = this.itemOptions.reduce((result, item) => {
          return result.concat(item.children)
        }, []).length
        this.generateCustomSelectedIds()
      })
    },
    generateCustomSelectedIds () {
      if (this.selectedIds.length === 0 && this.excludeIds.length > 0) {
        this.customSelectedIds = this.itemOptions
          .reduce((result, item) => {
            return result.concat(item.children)
          }, [])
          .filter((x) => !this.excludeIds.includes(x.id))
          .map((x) => x.id)
      } else {
        this.customSelectedIds = this.selectedIds
      }
    },
    onOverlayClick (val) {
      if (this.closeOnClickOverlay) {
        this.$emit('update:show', false)
      }
      this.$emit('onOverlayClick', val)
    },
    onLeftClick (val) {
      if (this.closeOnClickLeft) {
        this.$emit('update:show', false)
      }
      this.$emit('onLeftClick', val)
    },
    onRightClick (val) {
      if (this.closeOnClickRight) {
        this.$emit('update:show', false)
      }
      this.$emit('onRightClick', val, this.totalItemNum)
    }
  }
}
</script>
