/*
 * @Author: hwu
 * @Date: 2022-02-21 21:30:07
 * @Description: 商品相关
 * @LastEditTime: 2022-05-18 16:23:09
 */
import { crmHttp } from '@/utils/axios'

/**
 * 获取品牌菜谱商品列表
 * @param {String} brandCode 品牌编号
 * @param {Number} itemLevel 商品类型， 0-商品层，1-规格层，2-规格口味组（null 默认是规格口味组）
 * @returns
 */
export const listItemsForSelectPopupApi = (brandCode, itemLevel) =>
  crmHttp.get(`/items/for/pos`, { params: { brandCode, itemLevel } })
