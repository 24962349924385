<!--
 * @Author: hwu
 * @Date: 2021-05-23 09:35:11
 * @Description: popup弹窗组件，带遮罩层。
 * @LastEditTime: 2022-05-24 16:48:19
-->

<template>
  <div class="wh-popup-wraper" @touchmove.stop>
    <transition :name="'wh-popup-' + position">
      <div class="wh-popup-box" :class="position" :style="popupStyle" v-if="show">
        <slot></slot>
        <div v-if="showCloseButton" class="wh-popup-close" @click.stop="onClose">
          <i class="iconfont iconxiaochengxu_tubiao-dashanchu"></i>
        </div>
      </div>
    </transition>
    <transition name="wh-backdrop">
      <div class="wh-popup-backdrop" :style="maskStyle" @click="onClose" v-if="show && showMask"></div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'popup-box',
  props: {
    show: { type: Boolean, required: true, default: false },
    showMask: { type: Boolean, required: false, default: true },
    position: { type: String, required: false, default: 'bottom' },
    popupStyle: { type: String, required: false, default: '' },
    maskStyle: { type: String, required: false, default: '' },
    showCloseButton: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      showPopup: false,
      showAnimation: false
    }
  },
  watch: {
    show(val) {
      if (val) {
        setTimeout(
          function () {
            this.showPopup = true

            this.$nextTick(() => {
              this.showAnimation = true
            })
          }.bind(this),
          300
        )
      } else {
        this.showAnimation = false
        setTimeout(
          function () {
            this.showPopup = false
          }.bind(this),
          300
        )
      }
    }
  },
  methods: {
    onClose() {
      this.$emit('onClose')
    }
  }
}
</script>

<style lang="scss" scoped>
.wh-popup-box {
  position: fixed;
  width: 750px;
  background-color: $color-white;
  z-index: 2001;
  &.bottom {
    bottom: 0;
    left: 0;
    border-radius: 32px 32px 0 0;
    overflow: hidden;
  }
  &.top {
    top: 0;
    left: 0;
  }
  &.right {
    top: 0;
    right: 0;
  }
  &.left {
    top: 0;
    left: 0;
  }
}
.wh-popup-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  z-index: 2000;
}
.wh-popup-top-enter-active,
.wh-popup-top-leave-active,
.wh-popup-bottom-enter-active,
.wh-popup-bottom-leave-active,
.wh-popup-right-enter-active,
.wh-popup-right-leave-active,
.wh-popup-left-enter-active,
.wh-popup-left-leave-active {
  transition: transform 0.3s;
}

.wh-popup-top-enter,
.wh-popup-top-leave-to {
  transform: translateY(-100%);
}
.wh-popup-bottom-enter,
.wh-popup-bottom-leave-to {
  transform: translateY(100%);
}

.wh-popup-right-enter,
.wh-popup-right-leave-to {
  transform: translateX(100%);
}
.wh-popup-left-enter,
.wh-popup-left-leave-to {
  transform: translateX(-100%);
}
.wh-backdrop-enter-active,
.wh-backdrop-leave-active {
  transition: opacity 0.3s;
}
.wh-backdrop-enter,
.wh-backdrop-leave-to {
  opacity: 0;
}

.wh-popup-close {
  position: absolute;
  top: 16px;
  right: 24px;
  padding: 4px;
  z-index: 100;
  .iconfont {
    font-size: 42px;
    color: $color-text-sub;
  }
}
</style>
